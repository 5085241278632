/**
 * @generated SignedSource<<c895f8d74e08e7aa735bd1e082cdd2db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SftpAccountSettingsPageQuery$variables = {
  companyId: string;
};
export type SftpAccountSettingsPageQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SftpAccountSettingsPageChildComponentGetUserStatus_Fragment">;
  } | null;
};
export type SftpAccountSettingsPageQuery$rawResponse = {
  readonly node: {
    readonly __typename: "Company";
    readonly id: string;
    readonly sftpAccountStatus: {
      readonly forceChangePassword: boolean;
      readonly hasEmail: boolean;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SftpAccountSettingsPageQuery = {
  rawResponse: SftpAccountSettingsPageQuery$rawResponse;
  response: SftpAccountSettingsPageQuery$data;
  variables: SftpAccountSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SftpAccountSettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SftpAccountSettingsPageChildComponentGetUserStatus_Fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SftpAccountSettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SftpCognitoUserStatusResponse",
                "kind": "LinkedField",
                "name": "sftpAccountStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forceChangePassword",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasEmail",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa535f84c56bc92cc4d5a3b724fca7e5",
    "id": null,
    "metadata": {},
    "name": "SftpAccountSettingsPageQuery",
    "operationKind": "query",
    "text": "query SftpAccountSettingsPageQuery(\n  $companyId: ID!\n) {\n  node(id: $companyId) {\n    __typename\n    ...SftpAccountSettingsPageChildComponentGetUserStatus_Fragment\n    id\n  }\n}\n\nfragment SftpAccountSettingsPageChildComponentGetUserStatus_Fragment on Company {\n  sftpAccountStatus {\n    forceChangePassword\n    hasEmail\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5f7c0b160b4bdfbacf71a153e856c7c";

export default node;
